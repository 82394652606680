import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Mobileapp/hero'
import Seo from '../components/Seo'
import Achievement from '../components/whyvasundhara/achievement'
import allApiData from "../api";
import SeoheadData from '../siteScriptData'


function Whyvasundhara() {
    useEffect(() => {
        allApiData.aosinit()
    }, []);
    return (
        <Layout>
            <Seo SeoData={SeoheadData.whyvasundharaPageSeoData}></Seo>
            <main>
                <div className="why">
                    <Hero
                        cta="Get a Quote"
                        title="Why Vasundhara" def="The team at Vasundhara has consistently aimed to bring innovation and expertise to the table to deliver the best services to customers. Here are the top reasons to choose Vasundhara.">
                        <div className="hero-img hide-animation-768 my-lg-4 ms-auto m-0">
                            <img src="../assets/img/whyvasundhara/hero_img/why-vasundhara-infotech.svg" className="wh-auto z-index img-fluid" alt="why-img-one"  width="620" height="610" />
                            <img src="../assets/img/whyvasundhara/hero_img/1.svg" className=" why-img-one img-fluid" alt="why-img-one" />
                            <img src="../assets/img/whyvasundhara/hero_img/2.svg" className=" why-img-two img-fluid" alt="why-img-one" />
                        </div>
                        <div className="show-main-img-768">
                            <img src="../assets/img/whyvasundhara/hero_img/why-vasundhara-infotech-mobile.svg" className="wh-auto img-fluid" alt="why-img-one" width="620" height="610"/>
                        </div>
                    </Hero>
                </div >
                <Achievement />
            </main >
        </Layout >

    )
}

export default Whyvasundhara
