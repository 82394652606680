import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import allApidata from '../../api.js';

function Achievement() {
    const [alldata, setAlldata] = useState([]);
    const { year_of_experience, projects_accomplished } = alldata;

    useEffect(() => {
        allApidata.getHomeApi().then((data) => {
            setAlldata(data.footer_settings)
        })
    }, [])


    return (
        <div>
            <Container className='why-container'>
                <div className="achievement my-5 pb-100">
                    <Row className="row justify-content-center py-0 py-sm-5">
                        <Col lg={4} md={6} className="after-cross-top">
                            <div className="texter-achiev texter-achiev1 ms-auto extra-auto mt-120 p-35" data-aos="fade-right"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                                <div className="text-only text-right me-lg-3 me-2">
                                    <h4>{year_of_experience}+ Years of Experience</h4>
                                    <p>Vasundhara has been in the industry since 2013, because of which we know our way around the software development aspects.</p>
                                </div>
                                <div className="achieve-bg why-bg-all">
                                    <img src="../assets/img/whyvasundhara/eight-years-of-experiance.gif" alt="eight-years-of-experiance" className='wh-auto' width="115" height="115" />
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="after-top-middle mt-md-0 mt-4 d-lg-block d-flex">
                            <div className="texter-achiev texter-achiev2 flex-lg-column flex-row justify-content-center m-auto extra-auto mb-100 " data-aos="fade-down"
                                data-aos-easing="linear"
                                data-aos-duration="1500">
                                <div className="text-only text-lg-center text-left text-only1 mb-lg-3 mb-0 ms-lg-0 ms-2 order-lg-1 order-2">
                                    <h4>Innovation</h4>
                                    <p>We believe in adding value and innovation to each project we take up, which helps us serve our customers better. </p>
                                </div>
                                <div className="blank-gren-bg why-bg-all order-lg-2 order-1 why-bg-all1">
                                    <img src="../assets/img/whyvasundhara/innovation.gif" alt="innovation" className='wh-auto' width="115" height="115" />
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="after-cross-bottom mt-lg-0 mt-md-5 mt-4">
                            <div className="texter-achiev texter-achiev3 me-auto extra-auto mt-120 p-35" data-aos="fade-left">
                                <div className="contacts-bg why-bg-all order-lg-1 order-2">
                                    <img src="../assets/img/whyvasundhara/customer-first.gif" alt="customer-first" className='wh-auto' width="115" height="115" />
                                </div>
                                <div className="text-only text-lg-start text-right ms-lg-3 ms-0 me-lg-0 me-2 order-lg-2 order-1">
                                    <h4>Customer-First</h4>
                                    <p>For Vasundhara, customers always come first. Our focus lies on imparting the best experience to our customers, no matter the project.</p>
                                </div>

                            </div>
                        </Col>


                        <Col lg={4} md={6} className="after-why-middle  mt-lg-0 mt-md-5 mt-4">
                            <div className="d-flex h-100 extra-center ">
                                <div className="texter-achiev texter-achiev4" data-aos="fade-right"
                                    data-aos-offset="300"
                                    data-aos-easing="ease-in-sine">
                                    <div className="text-only text-lg-end text-left me-lg-3 me-0 ms-lg-0 ms-2 order-lg-1 order-2"  >
                                        <h4>Commitment</h4>
                                        <p>We are committed to helping our customers to the best of our abilities and technology blend. </p>
                                    </div>
                                    <div className="com-over-bg why-bg-all order-lg-2 order-1">
                                        <img src="../assets/img/whyvasundhara/commitment.gif" alt="commitment" className='wh-auto' width="115" height="115" />
                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={12} className="mt-md-0 mt-3">
                            <div className="texter-achiev texter-achiev5 justify-content-center m-auto extra-auto  mt-lg-0 mt-md-5 mt-4" data-aos="zoom-in">
                                <div className="main-v-circle d-flex justify-content-center align-items-center">
                                    <div className="jus-fill"></div>
                                    <div className="jus-fill1"></div>
                                    <div className="jus-fill2"></div>
                                    <h2 className='text-center'>why <br />vasundhara </h2>
                                    <img src="../assets/img/whyvasundhara/Achievement/whyvasundharalogo.png" alt="whyvasundharalogo" className='whyvasulogo' />
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="before-why-middle  mt-lg-0 mt-md-5 mt-4">
                            <div className="d-flex h-100 extra-center" data-aos="fade-left">
                                <div className="texter-achiev texter-achiev6 ms-auto extra-auto">
                                    <div className="over-vision-bg why-bg-all order-lg-1 order-2">
                                        <img src="../assets/img/whyvasundhara/quality-focused.gif" alt="quality-focused" className='wh-auto' width="115" height="115" />
                                    </div>
                                    <div className="text-only text-lg-start text-right ms-lg-3 ms-0 me-lg-0 me-2 order-lg-2 order-1">

                                        <h4>Quality-Focused</h4>
                                        <p> We at Vasundhara, ensure quality over quantity. Solid products, stable development lifecycle and dedication helps us achieve quality.</p>
                                    </div >
                                </div >
                            </div >
                        </Col >

                        <Col lg={4} md={6} className="before-cross-bottom  mt-lg-0 mt-md-5 mt-4">
                            <div className="texter-achiev texter-achiev7 ms-auto extra-auto mt-50" data-aos="fade-right"
                            >
                                <div className="text-only text-lg-end text-left me-lg-3 me-0 ms-lg-0 ms-2 order-lg-1 order-2">
                                    <h4>Global Talent Pool</h4>
                                    <p>The team at Vasundhara holds an experience of working with international customers along with understanding of global development protocols. </p>
                                </div>
                                <div className="ouver-his-bg why-bg-all order-lg-2 order-1">
                                    <img src="../assets/img/whyvasundhara/global-talent-pool.gif" alt="global-talent-pool" className='wh-auto' width="115" height="115" />
                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="before-top-middle  mt-md-5 mt-4">
                            <div className="texter-achiev texter-achie8 flex-lg-column flex-row justify-content-center m-auto extra-auto mt-54 mt-5" data-aos="fade-up"
                                data-aos-duration="3000">
                                <div className="blank-perple-bg why-bg-all order-lg-1 order-2 why-bg-all1">
                                    <img src="../assets/img/whyvasundhara/600-projects.gif" alt="600-projects" className='wh-auto' width="115" height="115" />
                                </div>
                                <div className="text-only text-lg-center text-right text-only1 mt-lg-3 mt-0 order-lg-2 order-1 me-lg-0 me-2">
                                    <h4>{projects_accomplished}+ Projects</h4>
                                    <p>We have worked with versatile customers and businesses of all sizes over the years with successful completion of {projects_accomplished}+ projects. </p>

                                </div>
                            </div>
                        </Col>

                        <Col lg={4} md={6} className="before-cross-top  mt-lg-0  mt-4 d-lg-block d-flex">
                            <div className="texter-achiev texter-achiev9 me-auto extra-auto mt-50 " data-aos="fade-left"
                            >
                                <div className="globle-bg why-bg-all">

                                    <img src="../assets/img/whyvasundhara/value-for-money.gif" alt="value-for-money" className='wh-auto' width="115" height="115" />
                                </div>
                                <div className="text-only text-left ms-lg-3 ms-2">


                                    <h4>Value for Money</h4>
                                    <p>We provide top-rated software development services at budget-friendly rates.</p>
                                </div>

                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Achievement
